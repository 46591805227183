.modal-body.create-task-modal {
    display: flex;
    flex-direction: row nowrap;
    padding: 20px;
    // min-height: 75vh;

    .select-tag {
        width: 100%;
    }

    .item {
        margin-bottom: 12px;
        &.item-inline {
            display: flex;
            align-items: center;
            > *:first-child {
                width: 200px;
            }
            &.project,
            &.reporter,
            &.tester,
            &.leaddev,
            &.inform,
            &.campaign,
            &.assignee {
                width: 100%;
                > *:first-child {
                    width: 188px;
                }
                > *:last-child {
                    width: 100%;
                }
            }
        }
    }

    h5 {
        font-size: 14px;
        font-weight: 500;
    }

    .field {
        margin-bottom: 20px;
        max-width: 610px;

        strong {
            color: #d10000;
        }

        .description {
            margin-top: 10px;
        }
    }

    .left {
        margin-right: 50px;
    }

    .right {
        .field {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            justify-content: space-between;
            align-items: center;

            .select-user,
            .select-project,
            .select-sprint,
            .select-campaign {
                width: 70%;
            }
        }

        .form-select,
        .form-control,
        .react-calendar {
            width: 70%;
        }
    }

    .point {
        .form-control {
            width: 100%;
        }
    }
}

.task-toastify {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
