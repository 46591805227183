.dashboard-sprint-columns-parent {
    position: relative;
    .button-scroll {
        position: absolute;
        top: 20px;
        background: transparent;
        width: 20px;
        z-index: 1;
        padding: 0;
        border-radius: 0;
        &.left {
            left: 14px;
        }
        &.right {
            right: 8px;
        }
        svg {
            width: 14px;
            height: 14px;
            color: rgba(255, 255, 255, 0.52);
        }
    }
    .dashboard-sprint-columns {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // background-color: #fff;
        background-color: transparent;
        border-radius: 16px;
        overflow-x: auto;
        position: relative;
        // padding: 0 12px;
        top: -56px;
        .error {
            position: absolute;
            font-size: 24px;
            transform: translate(400px, 150px);
        }
        .select-task{
            .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
                color: white;
            }
        }
    }

    //-----------------------------------------------------
    .status-bar {
        height: 56px;
        position: sticky;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 12px;
        background-color: var(--background-color-2);
    }
}

.status-v2 {
    text-transform: capitalize;
    font-size: 18px;
    text-align: center;
    height: 56px;
    font-weight: 400;

    .number-of-tasks {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        font-size: 14px;
        margin-left: 6px;
    }
}
