.edit-task-modal {
    .select-tag {
        width: 100%;
    }
    .item {
        margin-bottom: 12px;
        &.item-inline {
            display: flex;
            align-items: center;
            > *:first-child {
                width: 200px;
            }
            &.project, 
            &.reporter, 
            &.tester, 
            &.leaddev, 
            &.inform, 
            &.campaign, 
            &.deadline, 
            &.follower, 
            &.points, 
            &.assignee {
                width: 100%;
                > *:first-child {
                    width: 188px;
                }
                > *:last-child {
                    width: 100%;
                }
            }
            &.priority {
                width: 100%;
                > *:first-child {
                    width: 194px;
                }
                > *:last-child {
                    width: 100%;
                }
            }
        }
        .followers {
            font-size: 14px;
            font-style: italic;
            text-align: right;

            .follow-btn {
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}