.create-subtask {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .line-1 {
        display: flex;
        justify-content: space-around;

        input {
            margin-right: 10px;
        }

        .select-user {
            width: 60%;
        }
    }

    .line-2 {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
        .create-btn {
            margin-right: 15px;
        }
        .cancel-btn {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
    }
}

.main-task-content {
    padding: 0 0 12px;
    border-bottom: 1px solid #eee;
    padding-left: 50px;

    .sub-task-user {
        display: flex;
        align-items: center;

        .user-container {
            display: flex;
            align-self: center;
            margin-left: 8px;

            .subtask-assignee-avatar {
                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 30px;
                    object-fit: cover;
                }
            }
        }
    }
}
